<template>
  <v-container fluid class="pt-0 align-start justify-start d-print-none" fill-height>
    <v-row style="flex: 1 0 100%; align-items: center" class="py-0">
      <v-col cols="12" class="py-0 px-0">
        <v-toolbar color="grey lighten-5" elevation="1" height="55"
                   style="box-shadow: 0 2px 1px -1px rgba(0,0,0,.1), 0 1px 1px 0 rgba(0,0,0,.04), 0 1px 3px 0 rgba(0,0,0,.02) !important"
        >
          <v-toolbar-title class="pt-2" style="font-size: 1.2rem; line-height: 1.2rem">
            {{ `${page_title} (${organization.short_name})` }}
          </v-toolbar-title>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row style="flex: 1 1 100%; height: calc(100% - 55px)">
      <v-col cols="12" sm="12" md="8" xl="6">
        <v-card flat color="transparent">
          <v-card-text class="pt-0">
            <list_modal
                :dialog="list_dialog.dialog"
                :list_name="list_dialog.name"
                :list_group_name="list_dialog.group"
                :hierarchy="list_dialog.hierarchy"
                :title="list_dialog.title"
                :element_title="list_dialog.element_title"
                :document="list_dialog.document"
                :full_screen="list_dialog.full_screen"
                :hide_accounting="list_dialog.hide_accounting"
                @close="closeListDialog"
                @selectItem="selectItem"
                class="d-print-none"
            />
            <v-dialog v-model="report_dialog.dialog" fullscreen class="media-print">
              <PrintReport @closeModal="closeReportDialog" :component_name="report_dialog.component_name"/>
            </v-dialog>
            <div class="directories" v-if="directories.length">
              <div class="pb-2 font-weight-medium"
                   style="font-size: 1.1rem"
              >
                Довідники
              </div>
              <v-divider style="height: 3px"></v-divider>
              <div class="links px-2 pb-2 pt-4">
                <div :class="$vuetify.breakpoint.smAndDown ? 'link-xs' : 'link'"
                     v-for="(directory, idx) in directories"
                     :key="`dir-${idx}`"
                >
                  <div class="link-value">
                    <v-icon size="24" color="secondary"
                            class="link-icon"
                    >{{ directory.icon || 'mdi-file-document-outline' }}
                    </v-icon>
                    <template v-if="directory.route">
                      <a :href="directory.route">
                        {{ directory.element_title }}
                      </a>
                    </template>
                    <template v-else>
                      <a href=""
                         @click.prevent="openListDialog(
                     directory.list_name,
                     directory.list_group_name,
                     directory.hierarchy,
                     directory.list_title,
                     directory.element_title,
                     false,
                     directory.full_screen || false
                     )"
                      >
                        {{ directory.element_title }}
                      </a>
                    </template>

                  </div>
                </div>
              </div>
            </div>
            <div class="documents"
                 v-if="documents.length"
                 :class="directories.length ? 'mt-5' : 'mt-0'"
            >
              <div class="pb-2 font-weight-medium"
                   style="font-size: 1.1rem"
              >
                Документи
              </div>
              <v-divider style="height: 3px"></v-divider>
              <div class="links px-2 pb-2 pt-4">
                <div :class="$vuetify.breakpoint.smAndDown ? 'link-xs' : 'link'"
                     v-for="(doc, idx) in documents"
                     :key="`doc-${idx}`"
                >
                  <div class="link-value">
                    <v-icon size="24" color="success"
                            class="link-icon"
                    >{{ doc.icon || 'mdi-file-document-outline' }}
                    </v-icon>
                    <a href=""
                       @click.prevent="openListDialog(
                     doc.list_name,
                     doc.list_group_name,
                     doc.hierarchy,
                     doc.list_title,
                     doc.element_title,
                     true,
                     doc.full_screen,
                     doc.hide_accounting
                     )"
                    >
                      {{ doc.element_title }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="operations"
                 v-if="operations.length"
                 :class="documents.length ? 'mt-5' : 'mt-0'"
            >
              <div class="pb-2 font-weight-medium"
                   style="font-size: 1.1rem"
              >
                Операції
              </div>
              <v-divider style="height: 3px"></v-divider>
              <div class="links px-2 pb-2 pt-4">
                <div :class="$vuetify.breakpoint.smAndDown ? 'link-xs' : 'link'"
                     v-for="(doc, idx) in operations"
                     :key="`doc-${idx}`"
                >
                  <div class="link-value">
                    <v-icon size="24" color="error lighten-1"
                            class="link-icon"
                    >{{ doc.icon || 'mdi-focus-field-horizontal' }}
                    </v-icon>
                    <a href=""
                       @click.prevent="openListDialog(
                     doc.list_name,
                     doc.list_group_name,
                     doc.hierarchy,
                     doc.list_title,
                     doc.element_title,
                     true,
                     doc.full_screen
                     )"
                    >
                      {{ doc.element_title }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="reports"
                 v-if="reports.length"
                 :class="{
                   'mt-5': operations.length,
                   'mt-5': operations.length === 0 && documents.length,
                   'mt-0': !operations.length && !documents.length
                 }"
            >
              <div class="pb-2 font-weight-medium"
                   style="font-size: 1.1rem"
              >
                Звіти
              </div>
              <v-divider style="height: 3px"></v-divider>
              <div class="links px-2 pb-2 pt-4">
                <div :class="$vuetify.breakpoint.smAndDown ? 'link-xs' : 'link'"
                     v-for="(rep, idx) in reports"
                     :key="`rep-${idx}`"
                >
                  <div class="link-value">
                    <v-icon size="24" :color="rep.color || 'teal'"
                            class="link-icon"
                    >{{ rep.icon || 'mdi-chart-bar' }}
                    </v-icon>
                    <template v-if="rep.route">
                      <a :href="rep.route">
                        {{ rep.report_title }}
                      </a>
                    </template>
                    <template v-else>
                      <a href=""
                         @click.prevent="openReportDialog(
                     rep.component_name,
                     rep.report_title
                     )"
                      >
                        {{ rep.report_title }}
                      </a>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {accounting_pages, getDirectoryByGroup, getDocumentByGroup, getOperationByGroup, getReportByGroup} from "@/utils/accounting"
import {GET_ACCOUNTING_GENERAL_SETTINGS, UPDATE_INFORMATION_BY_ORGANIZATION} from "@/store/actions/organization";
import {GET_BANK_ACCOUNT} from "@/store/actions/bank";
import {onBeforeUnload} from "@/utils/icons"
import {mapGetters} from "vuex";

export default {
  name: "AccountingDesktop",
  props: ['page_name'],
  components: {
    list_modal: () => import("@/components/accounting/list_modal/list_modal"),
    PrintReport: () => import("@/components/PrintReport")
  },
  computed: {
    ...mapGetters({
      organization: 'getCurrentOrganization',
    })
  },
  data() {
    return {
      accounting_pages,
      directories: [],
      documents: [],
      reports: [],
      operations: [],
      page_title: '',
      list_dialog: {
        dialog: false,
        name: '',
        group: '',
        title: '',
        element_title: '',
        hierarchy: false,
        document: false,
        full_screen: false,
        hide_accounting: false
      },
      report_dialog: {
        dialog: false,
        component_name: '',
        report_title: ''
      }
    }
  },
  methods: {
    getDirectoryByGroup,
    getDocumentByGroup,
    getOperationByGroup,
    openReportDialog(component_name, report_title) {
      if (!component_name) {
        this.report_dialog.dialog = false
        this.report_dialog.component_name = ''
        this.report_dialog.report_title = ''
      }
      this.report_dialog.component_name = component_name
      this.report_dialog.report_title = report_title
      this.report_dialog.dialog = true
    },
    closeReportDialog() {
      this.report_dialog.dialog = false
      this.report_dialog.component_name = ''
      this.report_dialog.report_title = ''
    },
    openListDialog(list_name, list_group_name, hierarchy, title,
                   element_title, document = false, full_screen = false,
                   hide_accounting = false) {
      if (!list_name && !list_group_name) {
        this.list_dialog.dialog = false
        this.list_dialog.name = ''
        this.list_dialog.group = ''
        this.list_dialog.hierarchy = false
        this.list_dialog.title = ''
        this.list_dialog.element_title = ''
        this.list_dialog.document = false
        this.list_dialog.full_screen = false
        this.list_dialog.hide_accounting = false
      }
      this.list_dialog.name = list_name
      this.list_dialog.group = list_group_name
      this.list_dialog.dialog = true
      this.list_dialog.hierarchy = hierarchy
      this.list_dialog.title = title
      this.list_dialog.element_title = element_title
      this.list_dialog.document = document
      this.list_dialog.full_screen = full_screen
      this.list_dialog.hide_accounting = hide_accounting

    },
    closeListDialog() {
      this.list_dialog.name = ''
      this.list_dialog.dialog = false
      this.list_dialog.group = ''
      this.list_dialog.hierarchy = false
      this.list_dialog.title = ''
      this.list_dialog.element_title = ''
      this.list_dialog.document = false
      this.list_dialog.full_screen = false
    },
    selectItem(payload) {
      console.log(payload)
    }
  },
  watch: {
    page_name: {
      immediate: true,
      handler(payload) {
        const page_in_list = accounting_pages.find(i => i.name === payload)
        if (page_in_list) {
          this.directories = getDirectoryByGroup(payload)
          this.documents = getDocumentByGroup(payload)
          this.reports = getReportByGroup(payload)
          this.operations = getOperationByGroup(payload)
          this.page_title = page_in_list.title
        }
      }
    }
  },
  mounted() {
    window.removeEventListener('beforeunload', onBeforeUnload)
  },
  created() {
    this.$store.dispatch(GET_ACCOUNTING_GENERAL_SETTINGS)
    this.$store.dispatch(GET_BANK_ACCOUNT)
    this.$store.dispatch(UPDATE_INFORMATION_BY_ORGANIZATION)
  },
  beforeDestroy() {
    window.addEventListener('beforeunload', onBeforeUnload)
  }

}
</script>

<style scoped lang="scss">
.links {
  display: flex;
  flex-wrap: wrap;

  .link, .link-xs {
    display: flex;
    align-items: center;
    margin-bottom: 18px;

    .link-icon {
      margin-right: 12px;
      display: flex;
      align-items: center;
    }

    .link-value {
      display: flex;
      transition: 200ms ease-in-out all;
      padding-right: 12px;

      &:hover {
        a {
          border-bottom: thin solid #565656;
        }

        i {
          //color: var(--v-success-base);
          filter: brightness(.86) !important;
        }
      }

      a {
        text-decoration: none !important;
        text-underline: none;
        color: #3d3d3d;
        font-size: 1.05rem;
        transition: 200ms ease-in-out all;
        border-bottom: thin solid transparent;
      }
    }
  }
  .link-xs {
    flex: 1 1 100% !important;
  }
  .link {
    flex: 1 1 50% !important;
  }
}

</style>
